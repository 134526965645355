<template>
  <div class="bookings-body">
    <!-- Header -->
    <div class="booking-preset-name">
      {{ bookingsPreset.presetName }}
    </div>
    <div
      class="booking-preset-icon-wrapper"
      :class="{ search: declarations.length }"
    >
      <div
        v-if="declarations.length"
        @click="startSearch()"
        class="booking-preset-icon"
      >
        <icon icon="#cx-hea1-search" width="16" height="16"></icon>
      </div>
      <div @click="createDeclaration" class="booking-preset-icon">
        <icon icon="#cx-hea1-add" width="16" height="16"></icon>
      </div>
    </div>
    <div class="booking-preset-icon-wrapper bottom">
      <div @click="loadMore()" class="booking-preset-icon">
        <icon icon="#cx-hea1-arrow-left" width="16" height="16"></icon>
      </div>
    </div>
    <ul class="clebex-item-section pill mt-0 mb-0">
      <li v-if="search" class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper search">
          <search-component
            :ref="`search${bookingsPreset.presetId}`"
            :searchQuery="searchQuery[bookingsPreset.presetId]"
            @inputChanged="inputChanged($event)"
            @lostFocus="lostFocus(bookingsPreset.presetId)"
            @clearSearch="clearSearch()"
          ></search-component>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section pill bookings-header">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper grey-bg">
          <!-- resource -->
          <span @click="sortDeclarations('resourceName')" class="label">
            <span class="highlight">{{
              displayLabelName("bookings.bookings.resource")
            }}</span>
          </span>
          <!-- booking id -->
          <span @click="sortDeclarations('id')" class="label">
            <span class="highlight">{{
              displayLabelName("bookings.bookings.declaration-id")
            }}</span>
          </span>
          <!-- booking subject -->
          <span @click="sortDeclarations('subject')" class="label">
            <span class="highlight">{{
              displayLabelName("bookings.bookings.subject")
            }}</span>
          </span>
          <!-- host -->
          <span @click="sortDeclarations('user')" class="label">
            <span class="highlight">{{
              displayLabelName("bookings.bookings.host")
            }}</span>
          </span>
          <!-- start-time -->
          <span @click="sortDeclarations('datetime_from')" class="label">
            <span class="highlight">{{
              displayLabelName("bookings.bookings.start-time")
            }}</span>
          </span>
          <!-- cost -->
          <span @click="sortDeclarations('cost')" class="label">
            <span class="highlight">{{
              displayLabelName("bookings.bookings.cost")
            }}</span>
          </span>
          <!-- status -->
          <span @click="sortDeclarations('status')" class="label">
            <span class="highlight">{{
              displayLabelName("bookings.bookings.status")
            }}</span>
          </span>
          <!-- approval-status -->
          <span @click="sortDeclarations('approval_status')" class="label">
            <span class="highlight">{{
              displayLabelName("bookings.bookings.approval-status")
            }}</span>
          </span>
        </div>
      </li>
    </ul>
    <!-- Items -->
    <ul class="clebex-item-section pill mt-0 mb-0">
      <li
        v-for="(declaration, i) in declarations"
        :key="i"
        class="clebex-item-section-item row"
      >
        <router-link
          :to="{
            name: 'r_bookings-declaration',
            params: { id: declaration.id }
          }"
          class="clebex-item-content-wrapper"
        >
          <div class="booking-item-column">
            <div class="declaration-checkbox-wrapper">
              <div
                @click.stop="
                  declaration.status.status !== 'ENDED'
                    ? setSelectedDeclarations({
                        presetId: bookingsPreset.presetId,
                        declarationId: declaration.id
                      })
                    : ''
                "
                class="checkbox tiny alt block"
              >
                <input
                  :disabled="declaration.status.status === 'ENDED'"
                  @click.stop
                  type="checkbox"
                  :id="
                    `declaration-id-${bookingsPreset.presetId}-${declaration.id}`
                  "
                  name="user"
                />
                <label
                  :for="
                    `declaration-id-${bookingsPreset.presetId}-${declaration.id}`
                  "
                >
                  <svg-icon icon="checkmark-strait"></svg-icon>
                </label>
              </div>
              <icon
                @click.prevent="openModal(declaration)"
                :class="{
                  success:
                    declaration.approval_status &&
                    declaration.approval_status.status === 'APPROVED',
                  warning:
                    declaration.approval_status &&
                    declaration.approval_status.status === 'PENDING',
                  error:
                    declaration.approval_status &&
                    declaration.approval_status.status === 'DECLINED'
                }"
                icon="#cx-vis1-expected"
                width="28"
                height="28"
              ></icon>
            </div>
            <span class="column-name"
              >{{ displayLabelName("bookings.bookings.resource") }}:
            </span>
            <span class="highlight">{{ declaration.resourceName }}</span>
          </div>
          <div class="booking-item-column">
            <span class="column-name"
              >{{ displayLabelName("bookings.bookings.declaration-id") }}:
            </span>
            <span class="highlight">{{ declaration.id }}</span>
          </div>
          <div class="booking-item-column">
            <span class="column-name"
              >{{ displayLabelName("bookings.bookings.subject") }}:
            </span>
            <span class="highlight">{{ declaration.subject }}</span>
          </div>
          <div class="booking-item-column">
            <span class="column-name"
              >{{ displayLabelName("bookings.bookings.host") }}:
            </span>
            <span class="highlight"
              >{{ declaration.user.first_name }}
              {{ declaration.user.last_name }}</span
            >
          </div>
          <div class="booking-item-column">
            <span class="column-name"
              >{{ displayLabelName("bookings.bookings.start-time") }}:
            </span>
            <span class="highlight"
              >{{ displayDate(declaration.datetime_from) }}
              {{ displayTime(declaration.datetime_from) }}</span
            >
          </div>
          <div class="booking-item-column">
            <span class="column-name"
              >{{ displayLabelName("bookings.bookings.cost") }}:
            </span>
            <span class="highlight">{{ declaration.cost }}</span>
          </div>
          <div class="booking-item-column">
            <span class="column-name"
              >{{ displayLabelName("bookings.bookings.status") }}:
            </span>
            <span class="highlight">{{
              declaration.status ? declaration.status.name : ""
            }}</span>
          </div>
          <div class="booking-item-column">
            <span class="column-name"
              >{{ displayLabelName("bookings.bookings.approval-status") }}:
            </span>
            <div class="highlight">
              {{
                declaration.approval_status
                  ? declaration.approval_status.name
                  : ""
              }}
            </div>
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              class="declaration-arrow"
            ></icon>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import { mapGetters, mapActions, mapState } from "vuex";
import SearchComponent from "@/components/search/SearchComponent";

export default {
  name: "BookingsListView",
  components: { SearchComponent },
  data() {
    return {
      searchQuery: "",
      search: false,
      extend: 0,
      sortCategory: null,
      sortReversed: false
    };
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("search", ["presets"]),
    ...mapState("bookings", ["selectedDeclarations"]),
    declarations() {
      if (
        this.bookingsPreset &&
        this.bookingsPreset.resources &&
        this.bookingsPreset.resources.length
      ) {
        const query = this.searchQuery;
        if (query && query.length > 1) {
          return this.sorter(
            this.bookingsPreset.resources
              .filter(el => el.declarations.length)
              .map(el => {
                if (el.declarations.length) {
                  el.declarations.forEach(e => {
                    e.resourceName = el.name;
                  });
                }
                return el.declarations;
              })
              .flat()
              .filter(item =>
                `${item.resourceName} ${item.subject} ${item.user.first_name} ${item.user.last_name}`
                  .toLowerCase()
                  .includes(query.toLowerCase())
              )
          );
        } else {
          return this.sorter(
            this.bookingsPreset.resources
              .filter(el => el.declarations.length)
              .map(el => {
                if (el.declarations.length) {
                  el.declarations.forEach(e => {
                    e.resourceName = el.name;
                  });
                }
                return el.declarations;
              })
              .flat()
          );
        }
      }
      return [];
    }
  },
  methods: {
    ...mapActions("bookings", ["setSelectedDeclarations", "getBookings"]),
    displayDate(date) {
      if (!date) {
        return "";
      }
      return formatDate(DateTime.fromISO(date), this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return "";
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    openModal(declaration) {
      let uniqueDeclarations = [
        ...new Set(Object.values(this.selectedDeclarations).flat())
      ];
      if (uniqueDeclarations.length > 1) {
        this.$emit("openModal");
      } else {
        this.$router.push({
          name: "r_bookings-declaration",
          params: { id: declaration.id }
        });
      }
    },
    createDeclaration() {
      const preset = this.presets.find(
        el => el.id === this.bookingsPreset.presetId
      );
      if (preset) {
        this.$store.commit("bookings/setPreset", preset, {
          root: true
        });
      }
      this.$router.push({ name: "r_bookings-declaration-create" });
    },
    startSearch() {
      if (!this.search) {
        this.search = true;
        this.$nextTick(() => {
          this.$refs[
            `search${this.bookingsPreset.presetId}`
          ].$refs.searchComponent.focus();
        });
      } else {
        this.search = false;
      }
    },
    inputChanged(val) {
      this.searchQuery = val;
    },
    clearSearch() {
      this.searchQuery = "";
    },
    lostFocus() {
      setTimeout(() => {
        this.search = false;
      }, 100);
    },
    loadMore() {
      this.extend++;
      this.getBookings({
        resourceIds: null,
        presetId: this.bookingsPreset.presetId,
        extend: this.extend
      });
    },
    sortDeclarations(sortCategory) {
      if (this.sortCategory === sortCategory) {
        this.sortReversed = !this.sortReversed;
      } else {
        this.sortReversed = false;
        this.sortCategory = sortCategory;
      }
    },
    sorter(sortObj) {
      let firstSortFilter = null;
      if (this.sortCategory) {
        firstSortFilter = this.sortCategory;
      }
      if (firstSortFilter) {
        switch (firstSortFilter) {
          case "resourceName":
          case "subject":
            return sortObj.sort((a, b) => {
              const from = this.sortReversed
                ? b[firstSortFilter]
                : a[firstSortFilter];
              const to = this.sortReversed
                ? a[firstSortFilter]
                : b[firstSortFilter];
              return from.localeCompare(to);
            });
          case "user":
            return sortObj.sort((a, b) => {
              const from = this.sortReversed
                ? b[firstSortFilter].first_name
                : a[firstSortFilter].first_name;
              const to = this.sortReversed
                ? a[firstSortFilter].first_name
                : b[firstSortFilter].first_name;
              return from.localeCompare(to);
            });
          case "status":
          case "approval_status":
            return sortObj.sort((a, b) => {
              const from = this.sortReversed
                ? b[firstSortFilter]
                  ? b[firstSortFilter].name
                  : "zzz"
                : a[firstSortFilter]
                ? a[firstSortFilter].name
                : "zzz";
              const to = this.sortReversed
                ? a[firstSortFilter]
                  ? a[firstSortFilter].name
                  : "zzz"
                : b[firstSortFilter]
                ? b[firstSortFilter].name
                : "zzz";
              return from.localeCompare(to);
            });
          case "id":
            return sortObj.sort((a, b) => {
              const from = this.sortReversed
                ? b[firstSortFilter]
                : a[firstSortFilter];
              const to = this.sortReversed
                ? a[firstSortFilter]
                : b[firstSortFilter];
              return from - to;
            });
          case "datetime_from":
            return sortObj.sort((a, b) => {
              const from = this.sortReversed
                ? DateTime.fromISO(b[firstSortFilter])
                : DateTime.fromISO(a[firstSortFilter]);
              const to = this.sortReversed
                ? DateTime.fromISO(a[firstSortFilter])
                : DateTime.fromISO(b[firstSortFilter]);
              return from - to;
            });
          case "cost":
            return sortObj.sort((a, b) => {
              const from = this.sortReversed
                ? b[firstSortFilter]
                : a[firstSortFilter];
              const to = this.sortReversed
                ? a[firstSortFilter]
                : b[firstSortFilter];
              return from.localeCompare(to);
            });

          default:
            break;
        }
      } else {
        return sortObj;
      }
    }
  },
  watch: {
    presets() {
      this.extend = 0;
    },
    bookingsPreset: {
      deep: true,
      handler() {
        if (this.sortCategory) {
          this.sortDeclarations();
        }
      }
    }
  },
  props: {
    bookingsPreset: {
      type: Object,
      required: true
    }
  }
};
</script>
