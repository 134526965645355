<template>
  <div class="search-container">
    <input
      ref="searchComponent"
      type="text"
      @keyup="$emit('inputChanged', $event.target.value)"
      @blur="$emit('lostFocus')"
      @keypress.enter="$emit('enter')"
      :placeholder="displayLabelName('manage-users', 'users', 'search')"
      :value="searchQuery ? searchQuery : ''"
    />
    <button class="clear-field" @click="$emit('clearSearch')" type="button">
      <icon icon="#cx-app1-cross-round" width="14" height="14" />
    </button>
  </div>
</template>

<script>
export default {
  name: "SearchComponent",
  props: {
    searchQuery: {
      type: String,
      required: false
    }
  }
};
</script>
